import React, { useState } from 'react';
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAutoCompletes } from "../context/AutoCompleteContext";
import { useDataContext } from "../context/DataContext";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentType } from '../types/upload';
import { useUser } from '../context/UserContext';

export interface DealAutocompleteModel {
    name: string;
    isActive: boolean;
}

interface UploadDetails {
    option: string;
    selectedDealInternalName: DealAutocompleteModel;
    blobName: string;
    dealName?: string;
    year?: string;
    quarter?: string;
    file?: File;
}

interface UploadModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: UploadDetails) => void;
}


const dealInternalNameRequired = (option: string) => {

    const result = option == DocumentType.IcMemo ||
        option == DocumentType.QuarterlyReport ||
        option == DocumentType.CovenantReview ||
        option == DocumentType.ExitMemos;
    return result;
}

const dealNameRequired = (option: string) => {
    const result = option == DocumentType.IcMemo ||
        option == DocumentType.CovenantReview ||
        option == DocumentType.ExitMemos;

    return result;
}

const quarterRequired = (option: string) => {
    return option == DocumentType.QuarterlyReport ||
        option == DocumentType.DataTape;
}

const currentYear = new Date().getFullYear()

const internalNameValidationSchema = yup.object().shape({
    name: yup.string().required(),
    isActive: yup.boolean()
});

const validationSchema = yup.object({
    option: yup.string().required("Please select an option."),
    selectedDealInternalName: yup.object().when("option", {
        is: (val: string) => dealInternalNameRequired(val),
        then: (_) => internalNameValidationSchema,
    }),
    dealName: yup.string().when("option", {
        is: (val: string) => dealNameRequired(val),
        then: (schema) => schema.required("Please enter a deal name.")
    }),
    year: yup.string().when("option", {
        is: (val: string) => quarterRequired(val),
        then: (schema) => schema.required("Please enter a year.").test("is-valid-year", "Year cannot be in the future.", (value) => parseInt(value) <= currentYear),
    }),
    quarter: yup.string().when("option", {
        is: (val: string) => quarterRequired(val),
        then: (schema) => schema.required("Please select a quarter."),
    }),
});

const MuiUploadModal: React.FC<UploadModalProps> = ({
    isOpen,
    onClose,
    onSubmit,
}) => {

    const { borrowers, context_error } = useDataContext();
    const { autoCompletes, latestQuarter } = useAutoCompletes();
    const { user } = useUser();

    const {
        control,
        handleSubmit,
        watch,
        reset,
        setValue,
        resetField,
        formState: { errors },
    } = useForm<UploadDetails>({
        resolver: yupResolver<any>(validationSchema),
        defaultValues: {
            option: "",
            selectedDealInternalName: { name: "", isActive: true },
            dealName: "",
            year: "",
            quarter: "",
            blobName: "",
        },
    });

    const option = watch("option");
    const selectedDealInternalName = watch("selectedDealInternalName");
    const [deals, setDeals] = useState<string[]>([]);

    const dealOptions: DealAutocompleteModel[] = borrowers
        ? Object.entries(borrowers).map(([key, value]) => ({ name: key, isActive: value.isActive })).sort((a, b) => a.name.localeCompare(b.name))
        : [];

    const handleFormSubmit = (data: UploadDetails) => {
        reset();
        onClose();
        onSubmit(data);
    };

    const handleBorrowerNameChanged = (dealDetail: DealAutocompleteModel | null) => {
        if (!dealDetail) {
            resetField("dealName");
            resetField("blobName");
            setDeals([]);
            return;
        }

        if (borrowers) {
            const borrowerDetails = borrowers[dealDetail.name];
            if (borrowerDetails) {
                const dealData = Object.keys(borrowerDetails.nameIDMap).slice().reverse();
                setDeals(dealData);
                resetField("dealName");
                setValue("blobName", borrowerDetails.blobName);
            } else {
                resetField("dealName");
                resetField("blobName");
                setDeals([]);
            }
        }
        setDocumentTypeSpecificFields(option, dealDetail.name);
    };

    const handleDocumentTypeChanged = (documentType: string | null) => {
        if (!documentType) {
            return;
        }
        setDocumentTypeSpecificFields(documentType, selectedDealInternalName?.name);
    }

    const setDocumentTypeSpecificFields = (documentType: string, borrowerName: string | null) => {
        if (borrowerName && documentType == DocumentType.IcMemo &&
            autoCompletes && autoCompletes[borrowerName]) {
            setValue("dealName", autoCompletes[borrowerName])
            resetField("year");
            resetField("quarter");
        } else if (documentType == DocumentType.QuarterlyReport && latestQuarter) {
            const [currYear, currQuarter] = latestQuarter.split(".Q");
            setValue("year", currYear);
            setValue("quarter", currQuarter);
        } else if (documentType == DocumentType.MiscDocs) {
            resetField("year");
            resetField("quarter");
            resetField("dealName")
            setValue("blobName", "Misc")
        } else if (documentType == DocumentType.DataTape) {
            resetField("dealName")
            setValue("blobName", "Misc")
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm" closeAfterTransition={false}>
            <DialogTitle>Upload Details</DialogTitle>
            <DialogContent>
                {context_error && (
                    <p style={{ color: "red" }}>Error: {context_error}</p>
                )}
                <Controller
                    name="option"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error}>
                            <RadioGroup
                                {...field}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                    handleDocumentTypeChanged(value);
                                }}
                            >
                                {Object.values(DocumentType).filter(t => t !== DocumentType.DataTape && t !== DocumentType.ExitMemos && t !== DocumentType.CovenantReview).map((value) => (
                                    <>
                                        {value !== DocumentType.MiscDocs &&
                                            <FormControlLabel
                                                key={value}
                                                value={value}
                                                control={<Radio />}
                                                label={value} />
                                        }
                                        {value === DocumentType.MiscDocs && user?.isAdmin &&
                                            <FormControlLabel
                                                key={value}
                                                value={value}
                                                control={<Radio />}
                                                label={value} />
                                        }
                                    </>
                                ))}
                            </RadioGroup>
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                    )}
                />
                {dealInternalNameRequired(option) &&
                    <Controller
                        name="selectedDealInternalName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                                value={field.value}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                    handleBorrowerNameChanged(value);
                                }}
                                options={dealOptions}

                                getOptionLabel={(option: DealAutocompleteModel) => option.name}
                                renderOption={(props, option) => (
                                    <li {...props} key={`${option.name} ${option.isActive}`}>
                                        {option.name}
                                        {!option.isActive && <Chip
                                            label="exited"
                                            size="small"
                                            sx={{ marginLeft: 1 }}
                                        />
                                        }
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Deal internal name"
                                        fullWidth
                                        error={!!error}
                                    />
                                )}
                            />
                        )}
                    />}

                {dealNameRequired(option) &&
                    deals.length > 0 && (
                        <Controller
                            name="dealName"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth margin="normal" error={!!error} variant="outlined">
                                    <InputLabel id="deal-name-label">Deal Name</InputLabel>
                                    <Select {...field} labelId="deal-name-label" id="deal-name-label" label="Deal Name">
                                        {deals.map((deal, index) => (
                                            <MenuItem key={index} value={deal}>
                                                {deal}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                </FormControl>
                            )}
                        />
                    )}

                {quarterRequired(option) && (
                    <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                        <Controller
                            name="quarter"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl fullWidth error={!!error} variant="outlined">
                                    <InputLabel id="quarter-label">Quarter</InputLabel>
                                    <Select {...field} labelId="quarter-label" id="quarter-label" label="Quarter">
                                        <MenuItem value="1">Q1</MenuItem>
                                        <MenuItem value="2">Q2</MenuItem>
                                        <MenuItem value="3">Q3</MenuItem>
                                        <MenuItem value="4">Q4</MenuItem>
                                    </Select>
                                    {error && <FormHelperText>{error.message}</FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="year"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    error={!!error}
                                    label="Year"
                                    type="number"
                                    inputProps={{ max: new Date().getFullYear() }}
                                />
                            )}
                        />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { reset(); onClose(); }}>Cancel</Button>
                <Button onClick={handleSubmit(handleFormSubmit)} variant="contained" color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}



export default MuiUploadModal;