import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { loginRequest } from "../authConfig";

interface User {
    username: string;
    roles: string[];
    isAdmin: boolean;
}

interface UserContextType {
    user: User | null;
    isLoading: boolean;
}

const ADMIN_ROLE_NAME = "Uploader.Admin";

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { accounts, instance } = useMsal();

    useEffect(() => {
        if (accounts.length == 0) {
            return;
        }

        const getUserRoles = async () => {
            const currentAccount: AccountInfo = accounts[0];
            const tokenResponse = await instance.acquireTokenSilent({ ...loginRequest, account: currentAccount });
            if (!tokenResponse.accessToken) {
                return;
            }
            const decodedToken = jwtDecode<JwtPayload & { roles: string[] }>(tokenResponse.accessToken);
            const roles = decodedToken.roles || [];
            setUser({
                username: currentAccount.name || "Unknown",
                roles: roles,
                isAdmin: roles.some(r => r.toLowerCase() === ADMIN_ROLE_NAME.toLocaleLowerCase())
            });
            setIsLoading(false);
        }

        getUserRoles();
    }, [accounts, instance]);


    return (
        <UserContext.Provider value={{ user, isLoading: isLoading }}>
            {children}
        </UserContext.Provider>);
};

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
