import React, { useState, useEffect, useMemo } from "react";
import Banner from "../components/Banner";
import { useMsal } from "@azure/msal-react";
import { useError } from "../context/ErrorContext";
import axiosInstance from "../utils/axiosSetup";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  GridReadyEvent,
  ColumnResizedEvent,
  ITextFilterParams,
  IDateFilterParams,
  SortDirection,
  GridApi,
} from "ag-grid-community";
import * as XLSX from "xlsx";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import {
  DropdownActiveIcon,
  DropdownInactiveIcon,
} from "../assets/DropdownIcon";
interface ReconciliationData {
  Borrower: string;
  DealName: string;
  desiID: string;
  isOriginal?: boolean;
  Structure: string;
  Underwriter?: string;
  Preparer?: string;
  CloseDate?: Date;
}

const ReportPage: React.FC = () => {
  const [icmsData, setIcmsData] = useState<ReconciliationData[]>([]);
  const [quarterliesData, setQuarterliesData] = useState<ReconciliationData[]>(
    []
  );
  const [covenantsData, setCovenantsData] = useState<ReconciliationData[]>([]);

  const { instance } = useMsal();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [option, setOption] = useState<"icms" | "quarterlies" | "covenants">("icms");
  const { showError } = useError();

  useEffect(() => {
    const fetchReconciliation = async () => {
      try {
        const response = await axiosInstance.get(
          "/uploader/reconciliation",
          {}
        );
        if (response.status !== 200) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const { icms, quarterlies, covenants } = response.data;
        const transformData = (data: any[]): ReconciliationData[] =>
          data.map((deal: any) => {
            const { CloseDate, ...rest } = deal;
            return CloseDate !== "None"
              ? { ...rest, CloseDate: new Date(CloseDate) }
              : rest;
          });

        setIcmsData(transformData(icms));
        setQuarterliesData(transformData(quarterlies));
        setCovenantsData(transformData(covenants));

      } catch (e) {
        if (e instanceof Error) {
          showError(
            "Error connecting to server. Please try reloading the page, and contact support if the error persists."
          );
          console.error("Failed to fetch reconciliation data:", e.message);
        }
      }
    };

    if (instance.getActiveAccount()) {
      fetchReconciliation();
    }
  }, [instance]);

  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridApi]);

  const textFilterParams: ITextFilterParams = {
    filterOptions: ["contains", "notContains", "blank"],
    maxNumConditions: 1,
    buttons: ["apply", "reset"],
  };

  const dateFilterParams: IDateFilterParams = {
    filterOptions: ["lessThan", "greaterThan", "inRange", "equals", "blank"],
    maxNumConditions: 1,
    buttons: ["apply", "reset"],
  };

  const icmsColumnDefs = [
    {
      headerName: "Deal Internal Name",
      field: "DealInternalName",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sort: "asc" as SortDirection,
    },
    {
      headerName: "Borrower",
      field: "Borrower",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sort: "asc" as SortDirection,
    },
    {
      headerName: "Deal Name",
      field: "DealName",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Deal ID",
      field: "desiID",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Original",
      field: "isOriginal",
      filter: "agSetColumnFilter",
    },
    {
      headerName: "Structure",
      field: "Structure",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Underwriter",
      field: "Underwriter",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Close Date",
      field: "CloseDate",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: any) =>
        params.value ? new Date(params.value).toLocaleDateString() : "",
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
  ];

  const quarterliesColumnDefs = [
    {
      headerName: "Deal Internal Name",
      field: "DealInternalName",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sort: "asc" as SortDirection,
    },
    {
      headerName: "Borrower",
      field: "Borrower",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Original Deal Name",
      field: "DealName",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Deal ID",
      field: "desiID",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Structure",
      field: "Structure",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Preparer",
      field: "Preparer",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Original Close Date",
      field: "CloseDate",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: any) =>
        params.value ? new Date(params.value).toLocaleDateString() : "",
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
  ];

  const covenantsColumnDefs = [
    {
      headerName: "Borrower",
      field: "Borrower",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
      sort: "asc" as SortDirection,
    },
    {
      headerName: "Deal Name",
      field: "DealName",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Deal ID",
      field: "desiID",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
    {
      headerName: "Original",
      field: "isOriginal",
      filter: "agSetColumnFilter",
    },
    {
      headerName: "Close Date",
      field: "CloseDate",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      valueFormatter: (params: any) =>
        params.value ? new Date(params.value).toLocaleDateString() : "",
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",
      filterParams: textFilterParams,
    },
  ];


  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      resizable: false,
    }),
    []
  );

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onColumnResized = (params: ColumnResizedEvent) => {
    if (params.finished) {
      params.api.sizeColumnsToFit();
    }
  };

  const exportToExcel = () => {
    if (!gridApi) return;
    const rowData: ReconciliationData[] = [];
    gridApi.forEachNode((node: any) => rowData.push(node.data));

    let columnDefs = icmsColumnDefs;
    if (option === "covenants") {
      columnDefs = covenantsColumnDefs;
    } else if (option === "quarterlies") {
      columnDefs = quarterliesColumnDefs;
    }

    const headers = columnDefs.map((col) => col.headerName);
    const fields = columnDefs.map(
      (col) => col.field as keyof ReconciliationData
    );

    // Filter data to include only relevant fields
    const filteredRowData = rowData.map((row) => {
      const newRow: any = {};
      fields.forEach((field) => {
        if (row[field] !== undefined) {
          newRow[field] = row[field];
        }
      });
      return newRow;
    });

    const transformedData = filteredRowData.map((row) => {
      const newRow: any = {};
      fields.forEach((field, index) => {
        newRow[headers[index]] = row[field];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Adjust column widths
    const maxLengths = transformedData.reduce((acc, row) => {
      Object.keys(row).forEach((key) => {
        const length = String(row[key]).length;
        acc[key] = Math.max(acc[key] || 0, length, key.length);
      });
      return acc;
    }, {});
    worksheet["!cols"] = Object.keys(maxLengths).map((key) => ({
      wch: maxLengths[key] + 2,
    }));

    let sheetName = "IC Memos";
    let workbookName = "IC_Memos";
    if (option == "covenants") {
      sheetName = "Covenant";
      workbookName = "Covenant";
    } else if (option === 'quarterlies') {
      sheetName = "Quarterlies";
      workbookName = "Quarterlies";
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      sheetName
    );
    XLSX.writeFile(
      workbook,
      `${workbookName}.xlsx`
    );
  };

  const textStyling = "text-4xl text-[#000000]";

  let docTypeLabel = "IC Memos";
  let columnDefs = icmsColumnDefs;
  let rowData = icmsData;

  if (option === "covenants") {
    docTypeLabel = "Covenant reviews";
    columnDefs = covenantsColumnDefs;
    rowData = covenantsData;
  } else if (option == "quarterlies") {
    docTypeLabel = "Current Earnings";
    columnDefs = quarterliesColumnDefs;
    rowData = quarterliesData;
  }
  return (
    <main className="relative flex flex-col items-center min-h-screen bg-gray-100 overflow-hidden">
      <div className="w-full flex flex-col items-center">
        <Banner current="report" />
      </div>
      <div
        className="w-full flex flex-col items-center justify-center mt-4"
        style={{ height: "calc(100vh - 12rem)" }}
      >
        <div className="w-full h-1/8 flex flex-col items-center justify-center">
          <h1 className={`${textStyling}`}>
            Missing Documents Report for
            <Dropdown
              isOpen={dropdownOpen}
              onOpenChange={() => setDropdownOpen(!dropdownOpen)}
            >
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className={`ml-2 w-auto h-[3rem] px-0 pl-[0.5rem] ${textStyling}`}
                  endContent={
                    dropdownOpen ? (
                      <DropdownActiveIcon />
                    ) : (
                      <DropdownInactiveIcon />
                    )
                  }
                >
                  {docTypeLabel}
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Select report type"
                onAction={(key) => setOption(key as "icms" | "quarterlies")}  //| "covenants"
              >
                <DropdownItem className={`h-[3rem]`} key="icms">
                  <div className="text-xl flex justify-center">IC Memos</div>
                </DropdownItem>
                <DropdownItem className={`h-[3rem]`} key="quarterlies">
                  <p className="text-xl">Current Earnings</p>
                </DropdownItem>
                {/* <DropdownItem className={`h-[3rem]`} key="covenants">
                  <p className="text-xl">Covenant Reviews</p>
                </DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          </h1>
          <div className="w-3/4 h-1/2 flex flex-col items-end justify-end mb-[0.5rem]">
            <Button
              onClick={exportToExcel}
              className="bg-secondary text-white px-4 py-2 rounded"
            >
              Export to Excel
            </Button>
          </div>
        </div>

        <div className="ag-theme-alpine w-3/4 h-3/4">
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={20}
            onGridReady={onGridReady}
            onColumnResized={onColumnResized}
          />
        </div>
      </div>
    </main>
  );
};

export default ReportPage;
